





















import { Vue, Component } from 'vue-property-decorator'
import LoginDialogTemplate from '@/components/LoginDialogTemplate/LoginDialogTemplate.vue'
import userStore from '@/modules/users/store'
import { lassoxPortalUrl } from '@/lasso-urls'

@Component({
  components: {
    LoginDialogTemplate,
  },
})

export default class LoginToShowKeyfiguresDialog extends Vue {
  get options () {
    return userStore.loginToShowKeyfiguresDialogOptions
  }

  get show () {
    return !!this.options
  }

  get redirectUrl () {
    if (!this.options?.lassoId) return null
    return `${lassoxPortalUrl}/${this.options.lassoId}/cvrkeyfigures`
  }
}
