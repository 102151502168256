





















import { Vue, Component, Prop, Model } from 'vue-property-decorator'

@Component({})

export default class CustomCheckbox extends Vue {
  @Prop()
  readonly name: any

  @Prop()
  readonly value: any

  @Model('change')
  readonly modelValue: any

  focused = false

  get checked () {
    if (Array.isArray(this.modelValue)) {
      return this.modelValue.includes(this.value)
    }

    return this.modelValue === true
  }

  onChange (event: Event) {
    if (!(event.target instanceof HTMLInputElement)) return

    const { checked } = event.target

    if (Array.isArray(this.modelValue)) {
      const newModelValue = this.modelValue.slice(0)

      if (checked) {
        newModelValue.push(this.value)
      } else {
        newModelValue.splice(newModelValue.indexOf(this.value), 1)
      }

      this.$emit('change', newModelValue)
      return
    }

    this.$emit('change', checked)
  }
}
