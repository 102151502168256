<template>
  <div id="result-list-tab-menu">
    <div class="menu-tab" :class="{selected: type === entityType}" v-for="(type, index) in entityTypes" :key="index" @click="$emit('entity-type-changed', type)">{{type | searchTabTranslate}}</div>
  </div>
</template>

<script>
export default {
  name: 'result-list-tab-menu',
  props: {
    entityType: {
      type: String
    },
    entityTypes: {
      type: Array
    }
  },
  filters: {
    searchTabTranslate: function (string) {
      switch (string) {
        case 'all':
          return 'ALLE'
        case 'company':
          return 'VIRKSOMHEDER'
        case 'person':
          return 'PERSONER'
      }
    }
  }
}
</script>

<style lang="less">
  @import (reference) "~@oldShared/lasso/style/constants/colors";
  #result-list-tab-menu {
    background: @searchbar-tab-bottom-color;
    border-top: 1px solid @searchbar-border-color;
    display: flex;
    align-items: center;
    height: 41px;
    justify-content: space-between;
    padding: 20px 0px 20px 0px;
    width: 100%;

    .menu-tab {
      flex-grow: 1;
      flex-basis: 0;
      text-align: center;
      border-bottom: 2px solid @searchbar-tab-bottom-color;
      padding: 14px 0 12px;
      color: #212121;
      font-size: 12px;
      line-height: 12px;
      font-weight: 500;
      cursor: pointer;
      transition: border .15s ease-in-out, color .05s ease-in-out;

      &:hover {
        color: @searchbar-tab-bottom-active-color;
      }

      &.selected {
        color: @searchbar-tab-bottom-active-color;
        border-bottom: 2px solid @searchbar-tab-bottom-active-color;
      }
    }
  }
</style>
