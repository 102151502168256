import User, { ResponseUser } from './User'
import Vue from 'vue'
import { lassoxApiUrl } from '@/lasso-urls'
import type { HttpResponse } from 'vue-resource/types/vue_resource'

// HTTP functionality is added via vue-resource (see helpers.js) and cross-domain requests/bearer token sharing is enabled by xdomain, see index.ts
const vueHttp = (Vue as any).http

const lassoxApiRequest = (
  method: 'get' | 'post',
  path: string,
  body: any = undefined,
) => (
  (vueHttp[method](`${lassoxApiUrl}/${path}`, body) as Promise<HttpResponse>)
    .catch((result: unknown) => {
      if (result instanceof Error) throw result
      return result as HttpResponse
    })
)

const createUser = async (u: ResponseUser) => {
  const user = new User(u)

  // if (user.jobCompany.lassoId) {
  //   const name = await getName(user.jobCompany.lassoId)
  //   if (name) user.jobCompany.name = name
  // }

  return user
}

const api = {
  fetchUserInfo: () => (
    lassoxApiRequest('get', `users/me`)
      .then(response => {
        if (!response.ok) return null
        return response.json().then(createUser)
      })
      .catch(() => null)
  ),

  login: (model: {
    email: string
    password: string
  }) => lassoxApiRequest('post', `users/login/token/generate`, {
    user: model.email,
    password: model.password,
    remember: true,
  }),

  register: (model: {
    name: string
    email: string
    password: string
    returnUrl?: string
    // acceptedTerms: boolean
    // acceptedMarketing: boolean
  }) => lassoxApiRequest('post', `signup/lasso/lasso_lite?source=lasso.dk&sendWelcomeEmail=true${model.returnUrl ? model.returnUrl : ''}`, {
    email: model.email,
    password: model.password,
    organization: {
      name: model.name,
      contactName: model.name,
      contactEmail: model.email,
    },
  }),

  requestPasswordReset: (email: string) => lassoxApiRequest('post', `users/request-reset-password?email=${encodeURIComponent(email)}`),

  resendWelcomeMail: (email: string, lassoOrganizationId: string, returnUrl?: string) => lassoxApiRequest('post', `resend-welcome-mail${returnUrl ? returnUrl : ''}`, {
    email,
    lassoOrganizationId,
  }),

  // follow: (lassoIds: string[]) => request('POST', 'me/follow', {
  //   includeCredentials: true,
  //   body: lassoIds,
  // }),

  // unfollow: (lassoIds: string[]) => request('POST', 'me/unfollow', {
  //   includeCredentials: true,
  //   body: lassoIds,
  // }),
}

export default api
