


























































import { Vue, Component, Ref, Watch } from 'vue-property-decorator'
import Dialog from 'vue-lasso-core/components/Dialog.vue'
import LoadingSpinner from 'vue-lasso-core/components/LoadingSpinner.vue'
import Error from '../LoginDialogTemplate/parts/Error.vue'
import TextField from '@/components/new-fields/TextField.vue'
import Button from '../Button.vue'
import store from './store'
import { validateEmail } from '../LoginDialogTemplate/modules/validation'
import userApi from '@/modules/users/api'

@Component({
  components: {
    Dialog,
    LoadingSpinner,
    Error,
    TextField,
    Button,
  },
})

export default class ForgotPasswordDialog extends Vue {
  @Ref()
  readonly emailField!: TextField

  store = store

  loading = false

  done = false

  error = ''

  model = {
    email: {
      value: '',
      error: '',
    },
  }

  get hasErrors () {
    for (const field of Object.values(this.model)) {
      if (!!field.error) return true
    }

    return false
  }

  validateEmail () {
    this.model.email.error = validateEmail(this.model.email.value).error ?? ''
  }

  validate () {
    this.validateEmail()
  }

  submit () {
    this.validate()

    if (this.hasErrors) return

    this.loading = true

    userApi.requestPasswordReset(this.model.email.value).then(response => {
      if (!response.ok) {
        console.error('Request password reset failed. Data:', response.data)
        this.error = 'Noget gik galt'
        this.loading = false
        return
      }

      this.loading = false
      this.done = true
    })
  }

  @Watch('store.state.show')
  onShowChanged () {
    if (this.loading) return

    this.loading = false
    this.done = false
    this.error = ''
    this.model.email.value = ''
    this.model.email.error = ''
  }
}
