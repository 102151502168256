















import { Vue, Component, Prop } from 'vue-property-decorator'
import Icon from 'vue-lasso-core/components/Icon.vue'

@Component({
  components: {
    Icon,
  },
})

export default class Button extends Vue {
  @Prop({ type: String, default: 'normal' })
  readonly size!: string

  @Prop({ type: String, default: 'transparent' })
  readonly theme!: string

  @Prop(Boolean)
  readonly outlined!: boolean

  @Prop(Boolean)
  readonly disabled!: boolean

  @Prop({ type: String, required: false })
  readonly icon?: string

  @Prop({ type: String, default: '' })
  readonly label!: string

  @Prop({ type: String })
  readonly href?: string

  get attrs () {
    const vm = this

    const attrs: Record<string, unknown> = {
      get class () {
        return [
          'button',
          `button--size--${vm.size}`,
          `button--theme--${vm.theme}`,
          vm.disabled && 'is-disabled',
        ]
      },
      get disabled () { return vm.disabled },
    }

    if (this.href) {
      attrs.href = this.href
    } else {
      attrs.type = 'button'
    }

    return attrs
  }
}
