





















import { Vue, Component, Prop, Model } from 'vue-property-decorator'

@Component({})

export default class CustomRadioButton extends Vue {
  @Prop()
  readonly name: any

  @Prop()
  readonly value: any

  @Model('change')
  readonly modelValue!: any

  focused = false

  get checked () { return this.modelValue === this.value }
}
