<template>
  <div id="search-input" :class="{'focused': focusOnSearchBar, 'has-query': !!query}">
    <!-- Searchbar -->
    <div class="search-icon-wrapper">
      <img class="search-btn clickable" :src="focusOnSearchBar ? searchImage.focused : searchImage.nonFocused" @click="$refs.input.focus()">
    </div>
    <!-- <i class="search-icon fa fa-search" :class="{'focused': focusOnSearchBar}" @click="$refs.input.focus()"></i> -->
    <input class="search-field" type="text" ref="input"
      @focus="onFocus($event)" @blur="onBlur"
      @keydown.enter="$emit('keyboard-input', $event)"
      @keydown.down="$emit('keyboard-input', $event)"
      @keydown.up.prevent="$emit('keyboard-input', $event)"
      @keydown.esc="$emit('search-bar-focused', false);$refs['input'].blur()"
      v-model="newQuery" @input="emitInput(newQuery)"
      :placeholder="placeholder"
      >

    <!-- clear-query -->
    <transition name="fade-fast">
      <div class="input-clear-wrapper clickable" v-show="newQuery.length" @click="clear">
        <img class="clear-btn " src="../assets/img/svg/close_icon.svg?inline">
      </div>
    </transition>

    <transition name="fade-fast">
        <div class="border-splitter" v-show="newQuery.length"></div>
    </transition>

    <!-- Filter -->
    <transition name="fade-push-left">
      <dropdown ref="dropdown" class="popup-wrapper clickable" v-show="!isNarrow || focusOnSearchBar" left :min-width="300" :tab-index="1" :EventBus="EventBus">
        <div slot="toggle" class="button">
          <img class="button filter-btn" :src="statusFilter === 'all' ? filterImage.toggled : filterImage.nonToggled" alt="filter">
        </div>
        <template>
          <!-- toggle all/active entities -->
          <div class="d-flex flex-grow-1 justify-content-between align-items-center">
                <span class="text-muted" >Inkluder ophørte virksomheder</span>
                <toggle-button :value="statusToggle" @toggled="changeStatus()"></toggle-button>
              <!-- <div class="d-flex align-items-center">
              </div> -->
          </div>
        </template>
      </dropdown>
    </transition>
  </div>
</template>

<script>
import dropdown from '../../Dropdown'
import ToggleButton from '../../ToggleButton'
import { debounce } from 'lodash'

export default {
  name: 'search-input',
  props: {
    EventBus: {
      type: Object,
      required: true
    },
    query: {
      type: String,
      default: ''
    },
    statusFilter: {
      type: String
    },
    statusFilters: {
      type: Array
    },
    focusOnSearchBar: {
      type: Boolean
    },
    isNarrow: {
      type: Boolean
    },
    entityTypes: Array
  },
  components: {
    dropdown,
    ToggleButton
  },
  data () {
    return {
      newQuery: '',
      mobileBehavour: false,
      selectedIndex: -1,
      filterImage: {
        nonToggled: require('../assets/img/svg/Icons_Filter_icon_grey.svg?inline'),
        toggled: require('../assets/img/svg/Icons_Filter_icon_red.svg?inline')
      },
      searchImage: {
        nonFocused: require('../assets/img/svg/search_icon_grey.svg?inline'),
        focused: require('../assets/img/svg/search_icon_red.svg?inline')
      }
    }
  },
  watch: {
    query: {
      handler: function (val) {
        this.newQuery = val
      },
      immediate: true
    },
    focusOnSearchBar: {
      handler: function () {
        setTimeout(() => {
          if (this.focusOnSearchBar) this.$refs['input'].focus()
        }, 0)
        if (this.focusOnSearchBar && this.query.length > 0) {
          this.newQuery = this.query
        } else {
          if (this.query.length > 0) {
            this.newQuery = ''
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    clear: function () {
      this.$emit('query-changed', '')
      this.newQuery = ''
      this.$refs['input'].focus()
    },
    onFocus: function (event) {
      this.$emit('search-bar-focused', true)
      if (this.query.length) {
        setTimeout(() => event.target.select(), 0);
      }
    },
    onBlur: function () {},
    changeStatus: function () {
      this.$emit('status-changed', this.statusToggle ? 'active' : 'all')
      this.$refs['input'].focus()
      setTimeout(() => {
        this.$emit('search-bar-focused', true)
        this.$refs['dropdown'].open = false
      }, 400)
    },
    emitInput: debounce(function (typingQuery) {
      this.$emit('query-changed', typingQuery)
    }, 150)
  },
  computed: {
    statusToggle () {
      return this.statusFilter === 'all'
    },
    placeholderDesktop () {
      if (this.entityTypes.length > 1) return 'Søg i Lasso efter firmaer og personer'
      else return `Søg i Lasso efter ${this.entityTypes.indexOf('company') > -1 ? 'firmaer' : 'personer'}`
    },
    placeholderMobile () { return 'Søg i Lasso' },
    placeholder: function () {
      if (this.$route.name === 'entity' && !this.focus && this.$store.state.entities[this.$route.params.lassoId.toUpperCase()]) {
        var ent = this.$store.state.entities[this.$route.params.lassoId.toUpperCase()]
        return ent.name || (ent.firstName + ' ' + ent.lastName)
      } else return this.isNarrow ? this.placeholderMobile : this.placeholderDesktop
    }
  }
}
</script>

<style lang="less">
  @import "~@oldShared/lasso/style/constants/colors";
  @import "~@oldShared/lasso/style/base/animation";
  @import "~@oldShared/lasso/style/base/input";

  #search-input {
    display: flex;
    align-items: center;
    position: relative;
    background: white;
    z-index: 2;

    .search-icon-wrapper, .input-clear-wrapper, .popup-wrapper {
      height: 100%;
      width: auto;
      display: flex;
      align-items: center;
      padding: 0 15px;
    }
    img {
      width: 24px;
      &.search-btn {
        // width: 16px;
      }
      &.clear-btn {
        width: 14px;
      }
      &.filter-btn {
        width: 22px;
      }
    }

    //input search field
    input.search-field {
      border: 0;
      flex: 1;
      width: 100%;
      font-size: 13px;
      &::placeholder{
        overflow: hidden;
        text-overflow:ellipsis;
      }
      // IE inherit clear button
      &::-ms-clear {
        // display: none;
        height: 0;
        padding-left: 10px;
        width: 0;
      }
    }

    .border-splitter {
      border-left: 1px solid @searchbar-border-color;
      width: 1px;
      height: 45%;
    }

    //filter status
    .popup-wrapper {
      .toggle, .button, {
        height: 100%;
      }

      .menu {
        border-top: 1px solid #dee2e6;
        padding: 20px;
        cursor: default;
        .popup-menu-content {
          right: 0px;
          border-radius: 0 0 2px 2px;
          border: 1px solid rgba(50, 50, 50, 0.1);
          box-shadow: 0px 6px 10px 1px rgba(50, 50, 50, 0.1);
          position: absolute;
          &-inner {
            padding: 10px 8px 10px 20px;
          }
          .popup-menu-item {
            width: auto;
            font-size: 13px;
          }
        }
      }
    }
  }
</style>
