































import { Vue, Component, Prop, Model } from 'vue-property-decorator'
import CustomCheckbox from './CustomCheckbox.vue'
import Icon from './Icon.vue'

@Component({
  components: {
    CustomCheckbox,
    Icon,
  },
})

export default class Checkbox extends Vue {
  @Prop(Boolean)
  readonly dense!: boolean

  @Prop()
  readonly name: any

  @Prop()
  readonly value: any

  @Model('change')
  readonly modelValue: any

  @Prop({ type: String, default: 'check_box' })
  readonly icon!: string

  @Prop({ type: String, default: 'check_box_checked' })
  readonly checkedIcon!: string

  @Prop({ type: String, default: 'small' })
  readonly iconSize!: string

  @Prop({ type: String })
  readonly label?: string

  @Prop(Boolean)
  readonly required!: boolean

  @Prop({ type: String })
  readonly errorText?: string
}
