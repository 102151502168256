








































import { Vue, Component } from 'vue-property-decorator'
import Dialog from 'vue-lasso-core/components/Dialog.vue'
import AcceptMarketingField from '@/components/AcceptMarketingField.vue'
import Button from '@/components/Button.vue'
import store from './store'
import userApi from '@/modules/users/api'

@Component({
  components: {
    Dialog,
    AcceptMarketingField,
    Button,
  },
})

export default class ExternalLoginWelcomeDialog extends Vue {
  store = store
  userApi = userApi

  acceptMarketing = false
}
