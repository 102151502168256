import Vue from 'vue'
import VueResource from 'vue-resource'
import { keys, uniqBy } from 'lodash'

Vue.use(VueResource)

export let apiOrigin = (window.location.hostname.indexOf('lasso.dk') == 0 || window.location.hostname.indexOf('staging.lasso.dk') == 0)
  ? window.location.origin
  : (window.location.hostname == 'localhost' || window.location.hostname == '192.168.1.26')
    ? process.env.VUE_APP_API_URL || 'https://localhost:44331'
    : 'https://staging.lasso.dk'

export let apiUrl = apiOrigin + '/api'

Vue.http.options.root = apiUrl + '/entity'

export const EventBus = new Vue()

export let Entity = {
  get (lassoId) {
    return Vue.http.get(apiUrl + '/entity/' + lassoId).then(result => result.body)
  },
  network (lassoId) {
    return Vue.http.get(apiUrl + '/entity/' + lassoId + '/network').then(result => result.body)
  },
  resume (lassoId, shortVersion = false) {
    return Vue.http.get(apiUrl + '/entity/' + lassoId + '/resume?shortVersion=' + shortVersion).then(result => {
      result = result.body
      if (result.content) result.content = result.content.replace(/(<br\s*\/?\s*>\s*)+$/, '')
      return result
    })
  },
  statstidende (lassoId) {
    return Vue.http.get(apiUrl + '/entity/statstidende?cvr=' + lassoId.substring(6)).then(result => {
      return result.body
      // var count = parseInt(result.body.RestSearch['@resultCount'])
      // var res = []
      // if (count === 1) res.push(result.body.RestSearch.fulltextsearch.pri)
      // else if (count > 1) res = result.body.RestSearch.fulltextsearch.pri
      // return res
    })
  },
  follow (lassoId, email, name, company) {
    return Vue.http.post(apiUrl + '/entity/' + lassoId + '/followers?email=' + encodeURIComponent(email) + '&name=' + encodeURIComponent(name) + '&company=' + encodeURIComponent(company))
  },
  unfollow (lassoId, email) {
    return Vue.http.delete(apiUrl + '/entity/' + lassoId + '/followers?email=' + encodeURIComponent(email))
  },
  names (lassoIds) {
    return Vue.http.post(apiUrl + '/entity/names', lassoIds).then(result => result.body)
  },
  extractRelatedCompanyIds (entity) {
    let companies = (entity.lassoId.toUpperCase().indexOf('CVR-1') === 0)
      ? [...entity.board, ...entity.interests, ...entity.founders, ...entity.management, ...entity.owners].filter(e => e.participant.unitType === 'VIRKSOMHED').map(e => ({ name: e.participant.newestName, id: 'CVR-1-' + e.participant.businessKey }))
      : [...entity.bankruptcies.map(b => ({ name: b.name, id: 'CVR-1-' + b.id })), ...entity.person.relations.map(r => ({ name: r.company.displayName, id: r.company.lassoId.lassoId }))]
    return uniqBy(companies, (c) => c.id)
  },
  followedByToken (token) {
    return Vue.http.get(apiUrl + '/entity/followed?token=' + encodeURIComponent(token)).then(result => result.body)
  },
  acceptFollowTerms (token) {
    return Vue.http.post(apiUrl + '/entity/acceptterms?token=' + encodeURIComponent(token))
  }
}

export let Account = {
  get (lassoId) {
    return Vue.http.get(apiUrl + '/entity/' + lassoId + '/reports').then(result => result.body)
  }
}

export let Story = {
  get (storyId, lassoId) {
    return Vue.http.get(apiUrl + '/news/' + storyId + (lassoId ? '?lassoId=' + lassoId : '')).then(result => this.enrichStory(result.body))
  },
  getByLassoIds (lassoIds, page, pageSize, types = null) {
    return Vue.http.post(apiUrl + '/news?page=' + page + '&limit=' + pageSize + (!types ? '' : '&types=' + types.join(',')), lassoIds).then(result => result.body.map(this.enrichStory))
  },
  getList (listName, page, pageSize, orderBy, types) {
    return Vue.http.get(apiUrl + '/news/lists/' + listName + '?page=' + page + '&limit=' + pageSize + (orderBy ? '&orderBy=' + orderBy : '') + (types ? '&types=' + types.join(',') : '')).then(result => result.body.map(this.enrichStory))
  },
  enrichStory (story) {
    if (story) {
      story.imageId = 'https://lasso.blob.core.windows.net/lasso-news-images/' + story.imageId
      story.prettyHeadline = story.headline ? Vue.filter('linkify')(story.headline, true) : ''
      story.prettyTagline = story.tagLine ? Vue.filter('linkify')(story.tagLine, true) : ''
      story.slugTitle = story.prettyHeadline.toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-')
      for (var i = 0; i < story.lassoIds.length; i++) {
        var entity = story.lassoIds[i]
        if (entity.isMainId) story.mainEntity = { lassoId: entity.lassoId, id: entity.lassoId.substring(6), name: entity.relatedLassoIdName, type: entity.lassoId.indexOf('CVR-1') === 0 ? 'company' : 'person' }
        if (entity.inQuery) story.queryEntity = { lassoId: entity.lassoId, id: entity.lassoId.substring(6), name: entity.relatedLassoIdName, type: entity.lassoId.indexOf('CVR-1') === 0 ? 'company' : 'person' }
      }
      if (!story.queryEntity && story.mainEntity) story.queryEntity = story.mainEntity
    }
    return story
  },
  extractCompanyIds (stories) {
    let ids = {}
    let companyLassoIdRegEx = /CVR-1-\d{8}/g
    stories.forEach(story => {
      let allText = story.content + (story.tagLine || '') + (story.headline || '')
      let matches = allText.match(companyLassoIdRegEx) || []
      matches.forEach(id => { ids[id] = true })
    })
    return keys(ids)
  }
}

export let Contact = {
  contactLasso (message, directEmail) {
    return Vue.http.post(apiUrl + '/contact/message?requestContact=' + (directEmail ? true : false), message)
  },
  subscribeNewsletter (config) {
    return Vue.http.post(apiUrl + '/contact/newsletter?email=' + encodeURIComponent(config.email) + '&name=' + encodeURIComponent((config.name || '')) + '&company=' + encodeURIComponent((config.company || '')))
  }
}

export let Cookie = {
  set (name, value, days) {
    var expires = ''
    if (days) {
      var date = new Date()
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
      expires = '; expires=' + date.toUTCString()
    }
    document.cookie = name + '=' + (value || '') + expires
  },
  get (name) {
    var nameEQ = name + '='
    var ca = document.cookie.split(';')
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) === ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
    return null
  },
  remove (name) {
    document.cookie = name + '=; Max-Age=-99999999;'
  }
}
