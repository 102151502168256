<template>
  <div id="result-list">
    <div class="no-results text-muted" v-if="query.length > 0 && loading && !results.length">Søger efter {{entityType | searchTabTranslate}}...</div>
    <div class="no-results" v-if="results.length < 1 && !loading">Ingen resultater</div>
    <div v-bar ref="scroll" class="min-max-height dark dragger" v-show="results.length">
      <div class="v-bar-inner" ref="scroll-inner" @scroll="scrolling($event)">
        <div>
          <result-list-row v-for="(r, index) in results" :key="index" :class="{
            selected: index === selectedIndex,
            'plausible-event-name=company+result+click': r.entityType === 'Company',
            'plausible-event-name=person+result+click': r.entityType === 'Person',
          }" :entity="r" @entity-selected="entity => $emit('entity-selected', entity)" :is-narrow="isNarrow"></result-list-row>
          <div class="text-center text-muted padding-top-bottom-1" v-if="loadingNextPage && !noMoreResults" style="" >Henter flere resultater...</div>
          <div class="text-center text-muted padding-top-bottom-1" v-else-if="noMoreResults && results.length > 5" style="" >Ikke flere resultater</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import resultListRow from './ResultListRow'

export default {
  name: 'result-list',
  props: {
    query: {
      type: String
    },
    results: {
      type: Array,
      required: true
    },
    noMoreResults: {
      type: Boolean
    },
    loading: {
      type: Boolean
    },
    loadingNextPage: {
      type: Boolean
    },
    entityType: {
      type: String
    },
    isNarrow: {
      type: Boolean
    },
    selectedIndex: {
      type: Number
    }
  },
  data () {
    return {
      dots: '.'
    }
  },
  watch: {
    results: function () {
      if (this.results.length <= 6) this.$refs['scroll-inner'].scrollTop = 0
    }
  },
  methods: {
    scrolling: function(e) {
      if (e.target.scrollTop+e.target.offsetHeight+1 >= e.target.scrollHeight) {
          if (!this.noMoreResults) setTimeout(() => this.$emit('scrolled-to-bottom', true), 200)
      }
    },
  },
  mounted () {},
  components: {
    resultListRow
  },
  filters: {
    searchTabTranslate: function (string) {
      switch (string) {
        case 'all':
          return 'virksomheder og personer'
        case 'company':
          return 'virksomheder'
        case 'person':
          return 'personer'
      }
    }
  }
}
</script>

<style lang="less">
  #result-list {
    .no-results {
      background: white;
      padding: 20px 0;
      text-align: center
    }
    .v-bar-inner {
      max-height: 400px;
    }
    ::-webkit-scrollbar {
      display: none;
    }
  }
</style>
