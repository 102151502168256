












































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import Dialog from './Dialog.vue'
import RadioButtonGroup from './RadioButtonGroup.vue'
import RadioButton from './RadioButton.vue'
import SmileyField from './SmileyField.vue'
import TextField from './TextField.vue'
import Button from './Button.vue'

interface FeedbackModel {
  privateProfessional: 1 | 2 | 3 | null
  howOften: 1 | 2 | 3 | 4 | null
  trustworthiness: 1 | 2 | 3 | 4 | 5 | null
  understandableText: 1 | 2 | 3 | 4 | 5 | null
  adequateContent: 1 | 2 | 3 | 4 | 5 | null
  adequateContentFollowUp: string
}

@Component({
  components: {
    Dialog,
    RadioButtonGroup,
    RadioButton,
    SmileyField,
    TextField,
    Button,
  },
})

export default class FeedbackDialog extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  readonly options!: {
    onSubmit?: () => void
    onDismiss?: () => void
    onClose?: () => void
  }

  model: FeedbackModel = {
    privateProfessional: null,
    howOften: null,
    trustworthiness: null,
    understandableText: null,
    adequateContent: null,
    adequateContentFollowUp: '',
  }

  get useAdequateContentFollowUp () {
    return this.model.adequateContent !== null && this.model.adequateContent <= 2
  }

  get canSubmit () {
    return (
      this.model.privateProfessional !== null
      || this.model.howOften !== null
      || this.model.trustworthiness !== null
      || this.model.understandableText !== null
      || this.model.adequateContent !== null
    )
  }

  sanitizeModel () {
    const {
      privateProfessional,
      howOften,
      trustworthiness,
      understandableText,
      adequateContent,
    } = this.model

    const isNullOrInBounds = (value: number | null, min: number, max: number) => (
      value === null
      || !isNaN(value)
      && value >= min
      && value <= max
    )

    if (!isNullOrInBounds(privateProfessional, 1, 3)) {
      this.model.privateProfessional = null
    }

    if (!isNullOrInBounds(howOften, 1, 4)) {
      this.model.howOften = null
    }

    if (!isNullOrInBounds(trustworthiness, 1, 5)) {
      this.model.trustworthiness = null
    }

    if (!isNullOrInBounds(understandableText, 1, 5)) {
      this.model.understandableText = null
    }

    if (!isNullOrInBounds(adequateContent, 1, 5)) {
      this.model.adequateContent = null
    }

    if (!this.useAdequateContentFollowUp) {
      this.model.adequateContentFollowUp = ''
    }
  }

  submit () {
    this.sanitizeModel()

    if (!this.canSubmit) return

    const host = location.hostname == 'localhost'
      ? 'http://localhost:1338'
      : 'https://lasso.dk'

    fetch(host + '/api/contact/feedback', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(this.model),
    })
      .catch(err => { console.error('Request failed! Error:', err) })

    this.options.onSubmit?.()
  }

  dismiss () {
    this.options.onDismiss?.()
  }

  close () {
    if (this.canSubmit) this.submit()
    else this.dismiss()
    this.options.onClose?.()
  }
}
