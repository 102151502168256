var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{staticClass:"feedback-dialog",attrs:{"options":{
    size: 'medium',

    dense: true,

    scrollable: true,

    header: {
      title: 'Hjælp os med at blive bedre',
      closeButton: {
        show: true,
        onClick: _vm.close,
      },
    },

    backdrop: {
      show: true,
      onClick: _vm.close,
    },
  }}},[_c('form',{staticClass:"fields"},[_c('div',{staticClass:"field"},[_c('p',{staticClass:"label"},[_vm._v("Bruger du Lasso.dk privat eller i forbindelse med arbejde?")]),_c('RadioButtonGroup',_vm._l(({
            1: 'Privat',
            2: 'I forbindelse med arbejde',
            3: 'Begge dele',
          }),function(label,value){return _c('RadioButton',{key:value,attrs:{"name":"privateProfessional","label":label,"value":value},model:{value:(_vm.model.privateProfessional),callback:function ($$v) {_vm.$set(_vm.model, "privateProfessional", $$v)},expression:"model.privateProfessional"}})}),1)],1),_c('div',{staticClass:"field"},[_c('p',{staticClass:"label"},[_vm._v("Hvor ofte besøger du Lasso.dk?")]),_c('RadioButtonGroup',_vm._l(({
            1: 'Hver dag',
            2: 'Hver uge',
            3: 'Hver måned',
            4: 'Sjældnere',
          }),function(label,value){return _c('RadioButton',{key:value,attrs:{"name":"howOften","label":label,"value":value},model:{value:(_vm.model.howOften),callback:function ($$v) {_vm.$set(_vm.model, "howOften", $$v)},expression:"model.howOften"}})}),1)],1),_c('div',{staticClass:"field"},[_c('div',{staticClass:"smiley-field-group"},[_c('div',{staticClass:"smiley-field-group-item"},[_c('p',{staticClass:"label"},[_vm._v("Lasso.dk giver indtryk af, at organisationen bag er troværdig.")]),_c('SmileyField',{model:{value:(_vm.model.trustworthiness),callback:function ($$v) {_vm.$set(_vm.model, "trustworthiness", $$v)},expression:"model.trustworthiness"}})],1),_c('div',{staticClass:"smiley-field-group-item"},[_c('p',{staticClass:"label"},[_vm._v("Teksterne er skrevet i et letforståeligt sprog.")]),_c('SmileyField',{model:{value:(_vm.model.understandableText),callback:function ($$v) {_vm.$set(_vm.model, "understandableText", $$v)},expression:"model.understandableText"}})],1),_c('div',{staticClass:"smiley-field-group-item"},[_c('p',{staticClass:"label"},[_vm._v("Lasso.dk har alt det indhold, som jeg har brug for.")]),_c('SmileyField',{model:{value:(_vm.model.adequateContent),callback:function ($$v) {_vm.$set(_vm.model, "adequateContent", $$v)},expression:"model.adequateContent"}}),(_vm.useAdequateContentFollowUp)?[_c('div',{staticClass:"seperator"}),_c('TextField',{attrs:{"label":"Hvilket indhold savner du?","dense":"","rows":2},model:{value:(_vm.model.adequateContentFollowUp),callback:function ($$v) {_vm.$set(_vm.model, "adequateContentFollowUp", $$v)},expression:"model.adequateContentFollowUp"}})]:_vm._e()],2)])])]),_c('div',{staticClass:"seperator"}),_c('div',{staticClass:"buttons",attrs:{"slot":"footer"},slot:"footer"},[_c('Button',{on:{"click":_vm.close}},[_vm._v("Luk")]),_c('Button',{attrs:{"primary":"","disabled":!_vm.canSubmit},on:{"click":function () { return _vm.close(); }}},[_vm._v("Indsend")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }