











import { Vue, Component, Model, Prop } from 'vue-property-decorator'
import Checkbox from 'vue-lasso-core/components/Checkbox.vue'

@Component({
  components: {
    Checkbox,
  },
})

export default class AcceptMarketingField extends Vue {
  @Model('change', Boolean)
  readonly value!: boolean
  @Prop({ type: String, })
  readonly errorText?: boolean
}
