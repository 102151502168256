










































































import { Vue, Component, Prop, ProvideReactive } from 'vue-property-decorator'
import Dialog from 'vue-lasso-core/components/Dialog.vue'
import IconButton from 'vue-lasso-core/components/IconButton.vue'
import Button from '@/components/Button.vue'
import Icon from 'vue-lasso-core/components/Icon.vue'
import LoginDialogTemplateOptions from './options'
import userStore from '@/modules/users/store'
import router from './router/router'

const sizes = [ 400, 640, 900 ] as const
type Size = typeof sizes[number]

@Component({
  components: {
    Dialog,
    IconButton,
    Button,
    Icon,
  },
})

export default class LoginDialogTemplate extends Vue {
  @Prop({ type: Object, required: true })
  readonly options!: LoginDialogTemplateOptions

  userStore = userStore
  router = router

  availableWidth = 0

  get sizes () {
    const result: Size[] = [ sizes[0] ]

    for (const size of sizes.slice(1)) {
      if (size > this.availableWidth) break
      result.push(size)
    }

    return result
  }

  @ProvideReactive()
  get size () {
    return this.sizes[this.sizes.length - 1]
  }

  get columns () {
    const columns = [ 'login', 'intro' ]
    if (this.size >= 640) columns.reverse()
    return columns
  }

  beforeCreate () {
    router.reset()
  }

  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize)
    router.replace(this.options.initialRoute || 'signup')
  }

  onResize () {
    this.availableWidth = this.$el.getBoundingClientRect().width - (32 * 2)
  }

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  }
}
