
































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import Header from '../../parts/Header.vue'
import LoadingSpinner from 'vue-lasso-core/components/LoadingSpinner.vue'
import Error from '../../parts/Error.vue'
import TextField from '@/components/new-fields/TextField.vue'
import PasswordField from '@/components/new-fields/PasswordField.vue'
import Checkbox from 'vue-lasso-core/components/Checkbox.vue'
import AcceptMarketingField from '@/components/AcceptMarketingField.vue'
import Button from '@/components/Button.vue'
import LoginDialogTemplateOptions from '../../options'
import router from '../router'
import { validateEmail, validatePassword } from '../../modules/validation'
import userApi from '@/modules/users/api'
import userStore from '@/modules/users/store'
import confirmEmailSentDialogStore from '@/components/ConfirmEmailSentDialog/store'
import { trackGoal } from '@/utils/plausible'
import externalLoginProviders, { Provider } from '../../modules/external-login/providers'
import externalLoginPopup from '../../modules/external-login/popup'
import { lassoxAppsUrl } from '@/lasso-urls'
import { ExternalLoginCallbackResult } from '../../modules/external-login/popup'

@Component({
  components: {
    LoadingSpinner,
    Header,
    Error,
    TextField,
    PasswordField,
    Checkbox,
    AcceptMarketingField,
    Button,
  },
})

export default class Signup extends Vue {
  @Prop({ type: Object, required: true })
  readonly options!: LoginDialogTemplateOptions

  router = router
  externalLoginProviders = externalLoginProviders
  loading = false

  error = ''

  model = {
    name: {
      value: '',
      error: '',
    },
    email: {
      value: '',
      error: '',
    },
    password: {
      value: '',
      error: '',
    },
    marketingAccepted: {
      value: false,
      error: '',
    },
    termsAccepted: {
      value: false,
      error: '',
    },
  }

  fieldFocused = false
  get showCheckboxes () {
    return this.fieldFocused || this.model.name.value || this.model.email.value || this.model.password.value
  }

  get hasErrors () {
    for (const field of Object.values(this.model)) {
      if (!!field.error) return true
    }

    return false
  }

  validateEmail () {
    this.model.email.error = validateEmail(this.model.email.value).error ?? ''
  }

  validatePassword () {
    this.model.password.error = validatePassword(this.model.password.value).error ?? ''
  }

  validateTermsAccepted () {
    this.model.termsAccepted.error = this.model.termsAccepted.value
      ? ''
      : 'Dette felt er påkrævet'
  }

  validateMarketingAccepted () {
    this.model.marketingAccepted.error = this.model.marketingAccepted.value
      ? ''
      : 'Dette felt er påkrævet'
  }

  validate () {
    this.validateEmail()
    this.validatePassword()
    this.validateTermsAccepted()
    this.validateMarketingAccepted()
  }

  submit () {
    this.validate()

    if (this.hasErrors) return

    this.loading = true

    userApi.register({
      name: this.model.name.value,
      email: this.model.email.value,
      password: this.model.password.value,
      returnUrl: this.options.redirectUrl,
      // acceptedTerms: this.model.termsAccepted.value,
      // acceptedMarketing: this.model.marketingAccepted.value,
    }).then(response => {
      if (!response.ok) {
        switch (response.data.errorCode) {
          case 1: {
            if (response.data.errorMessage.indexOf('nonallowed characters')) {
              // that.signupError = 'Den indtastede e-mail kan ikke benyttes. Prøv venligst igen.'
              // that.signingUp = false
              this.error = 'Den indtastede e-mail kan ikke benyttes. Prøv venligst igen.'
              break
            }
          }

          case 2: {
            if (response.data.errorMessage.indexOf('already taken')) {
              // that.$http.post(that.apiUrl + '/users/login/available-organizations', model).then(function (organizations) {
              //   that.signupError = 'Den indtastede e-mail er allerede i brug'
              // })
              this.error = 'Den indtastede e-mail er allerede i brug.'
              break
            }
          }

          case 58: {
            // sessionStorage.setItem('lasso/signup/bannedEmail', 'true')
            // that.signupError = 'Den indtastede e-mail kan ikke bruges. Prøv med en anden e-mail.'
            // that.signingUp = false
            this.error = 'Den indtastede e-mail kan ikke bruges. Prøv med en anden e-mail.'
            break
          }

          case 59: {
            // that.signupError = 'Du har nået din grænse af prøveperioder. Kontakt venligst Lasso på +45 71 74 78 12.'
            // that.signingUp = false
            this.error = 'Den indtastede e-mail er allerede i brug.'
            break
          }

          default: {
            // that.signupError = 'Der skete en fejl. Prøv igen.'
            // that.signingUp = false
            // Raven.captureException('Signup error: ' + JSON.stringify(response.data))
            this.error = 'Der skete en fejl. Prøv igen.'
            console.error('Email signup failed. Data:', response.data)
            break
          }
        }

        this.loading = false
        return
      }

      trackGoal('Signup', {
        props: {
          provider: 'Email',
          trigger: this.options.trackingEventName.replaceAll('+', ' ')
        }
      })

      userStore.hideLoginDialog()
      confirmEmailSentDialogStore.show(this.model.email.value, response.data.lassoOrganizationId, this.options.redirectUrl || '')
    })
  }

  openExternalLoginPopup (provider: Provider) {

    externalLoginPopup.open(provider).then(result => {

      if (result.type === 'NewUser') {
        trackGoal('Signup', {
          callback: () => this.redirectAfterExternalLogin(result, this.options.redirectUrl),
          props: {
            provider: provider,
            trigger: this.options.trackingEventName.replaceAll('+', ' ')
          },
        })
      } else {
        trackGoal('Login', {
          callback: () => this.redirectAfterExternalLogin(result, this.options.redirectUrl),
          props: {
            provider: provider,
            trigger: this.options.trackingEventName.replaceAll('+', ' ')
          }
        })
      }
    })
    .catch(error => {
      if (error.errorCode === 110) this.error = `Du har allerede en bruger, som logges ind via ${error.errorMessage}`
      else if (error.errorCode === 16) this.error = 'Du har allerede en bruger, som logges ind vha. e-mail.'
      else if (error.errorCode === 58) this.error = 'E-mails fra det valgte domæne er ikke tilladt.'
      else this.error = 'Noget gik galt'
      console.error('External login failed. Error:', error)
    })
  }

  redirectAfterExternalLogin = (result: ExternalLoginCallbackResult, returnUrl?: string) => {
    const redirectUrl = new URL(`${lassoxAppsUrl}/login`)
    redirectUrl.searchParams.set('token', result.signInToken)
    if (returnUrl) redirectUrl.searchParams.set('returnUrl', returnUrl)
    window.location.href = redirectUrl.toString()
  }
}
