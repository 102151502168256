
import { Vue, Component, Prop } from 'vue-property-decorator'
import Icon from './Icon.vue'

@Component({
  components: {
    Icon,
  },
})

export default class IconButton extends Vue {
  @Prop(String)
  readonly icon?: string

  @Prop({ type: String, default: 'normal' })
  readonly size!: 'normal' | 'small'

  @Prop(Boolean)
  readonly isLink?: boolean

  @Prop(Boolean)
  readonly isRed?: boolean
}
