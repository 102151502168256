<template>
  <div id="results-dropdown" v-show="open">
    <result-list-tab-menu 
      v-if="entityTypes.length" 
      :entity-types="entityTypes" 
      :entity-type="entityType"
      @entity-type-changed="entityType => $emit('entity-type-changed', entityType)" 
    />
    <result-list 
      :query="query"
      :loading="loading"
      :loading-next-page="loadingNextPage"
      :results="results"
      :no-more-results="noMoreResults" 
      :entity-type="entityType"
      :is-narrow="isNarrow"
      :selected-index="selectedIndex"
      @entity-selected="entity => $emit('entity-selected', entity)"
      @scrolled-to-bottom="atBottom => $emit('scrolled-to-bottom', atBottom)"
    />
  </div>
</template>

<script>
import resultListTabMenu from './Results/ResultListTabMenu'
import resultList from './Results/ResultList'

export default {
  name: 'results-dropdown',
  props: {
    query: {
      type: String
    },
    open: {
      type: Boolean,
      default: false
    },
    entityTypes: {
      type: Array,
      required: true
    },
    entityType: {
      type: String,
      required: true
    },
    results: {
      type: Array,
      required: true
    },
    noMoreResults: {
      type: Boolean
    },
    loading: {
      type: Boolean
    },
    loadingNextPage: {
      type: Boolean
    },
    isNarrow: {
      type: Boolean
    },
    selectedIndex: {
      type: Number
    }
  },
  components: {
    resultListTabMenu,
    resultList
  },
  created () {},
  methods: {}
}
</script>

<style lang="less">
  #results-dropdown {
    position: absolute;
    background: white;
    width: 100%;  
    box-shadow: 1px 1px 8px rgba(0,0,0,.15);
    z-index: 1;
    left: 0;
    .narrow & {
      box-shadow: 1px 5px 8px rgba(0,0,0,.10);
    }
  }
</style>