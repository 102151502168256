





















import { Vue, Component } from 'vue-property-decorator'
import LoginDialogTemplate from '@/components/LoginDialogTemplate/LoginDialogTemplate.vue'
import userStore from '@/modules/users/store'
import { lassoxPortalUrl } from '@/lasso-urls'

@Component({
  components: {
    LoginDialogTemplate,
  },
})

export default class LoginToFollowDialog extends Vue {
  get show () {
    return !!userStore.loginToFollowDialogOptions
  }
  
  get options () {
    return userStore.loginToFollowDialogOptions
  }

  get entity () {
    return userStore.loginToFollowDialogOptions?.entity
  }

  get redirectUrl () {
    if (!this.entity) return null
    return `${lassoxPortalUrl}/${this.entity.lassoId}`
  }

  async onLoggedIn () {
    const user = userStore.user
    const entity = this.entity

    if (!user || !entity) return

    // TODO
    // await user.followEntities([ entity ])
  }
}
