<template>
  <div id="result-list-row" class="result-list-row" @click="$emit('entity-selected', entity)" @mouseenter="hover = true" @mouseleave="hover = false" :class="entityStatus">
    <img v-if="!entity.pep" :class="entity.entityType.toLowerCase()" :src="hover ? img[entity.entityType.toLowerCase()].hover : img[entity.entityType.toLowerCase()].default" alt="">
    <pep class="is-pep" v-else style="position: relative; left: -3px; font-size: .6em" placement="right" :id="entity.lassoId" :pep-data="entity.pep"></pep>
    <div class="entity-wrapper">
      <div class="entity-name-tags-wrapper">
        <h3 class="entity-name ellipsis" :class="entityStatus">
          {{entity.name}}
          <span class="entity-found-by-name text-muted" v-if="foundBySecondaryName && entityTypeIsCompany && entity.foundByName !== null" style="" :title="'Binavn: '+ entity.foundByName">(<span v-if="!isNarrow">Binavn: </span>{{entity.foundByName}})</span>
        </h3>
        <div class="entity-tags" v-if="entity.tags">
          <div class="loader" v-if="entity.tags.checking"></div>
          <div v-else style="display:flex;justify-items:center">
            <span class="tag" v-for="tag in tagsResponsive.slice" :key="tag.id" :title="tag.name">{{tag.name}}</span>
            <span class="tag" v-show="tagsResponsive.above">+ {{tagsResponsive.count}} flere</span>
          </div>
        </div>
      </div>
      <div class="entity-address-company-meta-wrapper text-muted" :class="entity.entityType.toLowerCase()">
        <p class="entity-address text-muted" style="">{{address}}</p>
        <p class="entity-cvr-companies ellipsis" v-text="entityTypeIsCompany ? 'CVR: ' + companyCVR : personCompanies.name" :style="[!entityTypeIsCompany && entity.companies.length > 1 ? {'padding-right':'5px'}:{'padding-right':'0'} ]"></p>
        <span class="entity-person-more-companies" v-if="!entityTypeIsCompany && personCompanies.more">{{personCompanies.more}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import pep from '@oldShared/lasso/vue-components/pep'
export default {
  name: 'result-list-row',
  props: {
    entity: {
      type: Object,
      required: true
    },
    isNarrow: {
      type: Boolean
    }
  },
  components: {
    pep
  },
  data () {
    return {
      img: {
        company: {
          default: require('../../assets/img/svg/company_icon_grey.svg?inline'),
          hover: require('../../assets/img/svg/company_icon_red.svg?inline')
        },
        person: {
          default: require('../../assets/img/svg/person_icon_grey.svg?inline'),
          hover: require('../../assets/img/svg/person_icon_red.svg?inline')
        }
      },
      hover: false
    }
  },
  computed: {
    entityTypeIsCompany: function () {
      return this.entity.entityType.toLowerCase() === 'company'
    },
    address: function () {
      if (this.entityTypeIsCompany && this.entity.address1) {
        return `${this.entity.address1}${ this.entity.postalCode ? `, ${this.entity.postalCode}` : ''}${ this.entity.city ? ` ${this.entity.city}` :''}`
      } else if (this.entity.postalCode && this.entity.city) {
        return this.entity.postalCode + ' ' + this.entity.city
      } else if (!this.entityTypeIsCompany && !this.entity.city && this.entity.address1) {
        const validFromIndex = this.entity.address1.indexOf('Valid from:')
        let address = validFromIndex > -1
          ? this.entity.address1.substring(0, validFromIndex - 1)
          : this.entity.address1
        address = address.trim()
        address = address.replaceAll(/\r?\n/g, ', ')
        address = address.replaceAll(/((^|,)[\s]*null([\s]+|(?=,|$)))/gm, '')
        return address
      } else {
        return 'Ingen adresse registreret i CVR'
      }
    },
    foundBySecondaryName: function () {
      return this.entity.name !== this.entity.foundByName && this.companyCVR !== this.entity.foundByName
    },
    entityName: function () {
      return this.entity.name
    },
    entityStatus: function () {
      if (this.entity.status && (this.entity.status.toLowerCase() === 'opløst' || this.entity.status.toLowerCase() === 'ophørt'))  return 'bankrupt'
      else return ''
    },
    personCompanies: function () {
      let personCompaniesObj = { name: null, more: null }
      if (this.entity.companies.length === 1) personCompaniesObj.name = this.entity.companies[0].name
      else if (this.entity.companies.length > 1) {
        personCompaniesObj.name = this.entity.companies[0].name
        personCompaniesObj.more = ' + ' + (this.entity.totalCompanyCount-1) + ' flere'
      }
      return personCompaniesObj
    },
    companyCVR: function () {
      return this.entity.lassoId.split('-')[2]
    },
    tagsResponsive: function () {
      let modifiedTags = {
        slice: !this.isNarrow ? this.entity.tags.slice(0, 3) : this.entity.tags.slice(0, 1),
        above: !this.isNarrow ? this.entity.tags.length > 3 : this.entity.tags.length > 1,
        count: !this.isNarrow ? this.entity.tags.length-3 : this.entity.tags.length-1
      }
      return modifiedTags
    }
  },
  created () {},
  mounted () {},
  methods: {}
}
</script>

<style lang="less">
  @import (reference) "~@oldShared/lasso/style/constants/colors";

  #result-list-row {
    background: white;
    padding: 20px;
    // height: 77px;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-right: 0px;

    .is-pep {
      margin-right: 13px;
    }

    &:hover, &.selected {
      background: @searchbar-list-row-color;
      .entity-name {
        color: @red-primary !important;
      }
    }
    &.bankrupt {
      .entity-wrapper {
        .entity-name, .entity-address-company-meta-wrapper .entity-address, .entity-cvr-companies {
            color: @searchbar-entity-name-bankrupt;
            // opacity: .8;
        }
      }
    }

    // IE 11 & Edge
    _:-ms-lang(x), & {
      margin-right:-20px;
    }

    img {
      margin: 0 20px 5px 0;
      &.person {
        width: 15px;
        height: 15px;
      }
      &.company {
        width: 17px;
        height: 14px;
      }
    }

    .entity-wrapper {
      flex-grow: 1;
      overflow:hidden;
      // position: relative;

      .entity-name-tags-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2px;

        .entity-name {
          color: #212121;
          font-size: 14px;
          line-height: 16px;
          font-weight: 500;
          margin-bottom: 0px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: none;
          flex-grow: 0;
          flex-shrink: 1;
          margin-right: 15px;
          .narrow & {
            text-overflow: ellipsis;
          }

          .entity-found-by-name {
            font-weight: 400;
            margin-left: 5px;
            font-size: 12px;
            color: @text-muted;
          }
        }
        .pep-wrapper {
          top: -1px;
        }
        .entity-tags {
          display: flex;
          justify-content: flex-end;
          line-height: 16px;
          flex-grow: 1;
          flex-shrink: 0;
          font-size: 12px;
          white-space: nowrap;

          .loader {
            flex-grow: 0;
          }

          .tag {
            font-weight: 400;
            margin-bottom: 0;
            background-color: @searchbar-entity-tag-color;
            color: white;
            border-radius: 2px;
            font-size: 10px;
            padding: 0px 5px;
            margin-bottom: 0;
            max-width: 120px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            &+.tag {
              margin-left: 5px;
            }
          }
        }
      }
      .entity-address-company-meta-wrapper {
        font-size: 12px;
        line-height: 16px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: @text-muted;

        .entity-address {
          white-space: nowrap;
          overflow: hidden;
          flex-grow: 1;
          flex-shrink: 1;
          margin-right: 15px;
          margin-bottom: 0;
          width: auto;
          text-overflow: ellipsis;
          color: @text-muted;
          .narrow & {
            width: 100%;
          }
        }

        .entity-cvr-companies {
          margin-bottom: 0;
          width: auto;
          white-space: nowrap;
          flex-grow: 1;
          flex-shrink: 0;
          text-align: right;
          .person & {
            flex-shrink: 1;
            flex-grow: 0;
          }
        }

        &.person {
          .entity-cvr-companies {
            flex-shrink: 1;
            flex-grow: 0;
            // padding-right: 5px;
          }
          .entity-person-more-companies {
            white-space: nowrap;
          }
        }

        .narrow &.person {
          .entity-address {
              width: 40%;
          }
          .entity-cvr-companies {
            flex-shrink: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .entity-person-more-companies {
            flex-grow: 0;
            flex-shrink: 0;
            white-space: nowrap;
          }
        }
      }
    }
  }
  .result-list-row {
    border-bottom: 1px solid @searchbar-list-border-bottom-color;
  }
</style>
