


























import { Vue, Component, Prop, InjectReactive } from 'vue-property-decorator'
import IconButton from 'vue-lasso-core/components/IconButton.vue'
import userStore from '@/modules/users/store'
import router from '../router/router'

@Component({
  components: {
    IconButton,
  },
})

export default class Header extends Vue {
  @Prop({ type: String, required: false })
  readonly title?: string

  @InjectReactive()
  readonly size!: number

  userStore = userStore
  router = router

  get isSmall () { return this.size < 900 }
}
