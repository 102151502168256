var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"duration":{ enter: 150, leave: 75 }}},[(_vm.store.state.show)?_c('Dialog',{staticClass:"confirm-email-sent-dialog",attrs:{"options":{
      size: 400,

      header: {
        title: 'Bekræft din e-mail',
        closeButton: {
          show: true,
          onClick: function () { return _vm.store.hide(); },
        },
      },

      backdrop: {
        show: true,
        onClick: function () { return _vm.store.hide(); },
      },
    }}},[_c('div',{staticClass:"banner"},[_c('div',{staticClass:"banner-image"})]),_c('div',{staticClass:"body"},[_c('p',[_vm._v("Du er der næsten!")]),_c('p',[_vm._v("Vi har sendt en mail til "),_c('strong',[_vm._v(_vm._s(_vm.store.state.email))]),_vm._v(".")]),_c('p',[_vm._v("Klik på knappen i mailen for at komme i gang.")]),(_vm.store.state.lassoOrganizationId)?_c('p',[_c('br'),_vm._v(" Har du ikke modtaget mailen? "),_c('a',{attrs:{"href":"#"},on:{"click":_vm.resendWelcomeEmail}},[_vm._v("Send igen.")]),_c('br')]):_vm._e(),(_vm.sentTime)?_c('p',[_vm._v("Sendt kl. "+_vm._s(_vm.sentTime.toLocaleTimeString("da-DK"))+".")]):_vm._e()])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }