























import { Vue, Component } from 'vue-property-decorator'
import LoginDialogTemplate from '@/components/LoginDialogTemplate/LoginDialogTemplate.vue'
import userStore from '@/modules/users/store'

@Component({
  components: {
    LoginDialogTemplate,
  },
})

export default class LoginDialog extends Vue {
  get options () {
    return userStore.loginDialogOptions
  }

  get show () {
    return !!this.options
  }
}
