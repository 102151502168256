









































import { Vue, Component, Prop } from 'vue-property-decorator'
import IconButton from './IconButton.vue'
import PartialDeep from '../types/PartialDeep'
import DialogOptions from './DialogOptions'

@Component({
  components: {
    IconButton,
  },
})

export default class Dialog extends Vue {
  @Prop({
    type: Object,
    default: () => ({}),
  })
  readonly options!: PartialDeep<DialogOptions>

  get finalOptions (): DialogOptions {
    const { options } = this

    return {
      size: options.size ?? 'small',

      dense: options.dense ?? false,

      scrollable: options.scrollable ?? false,

      header: {
        title: options.header?.title ?? '',
        closeButton: {
          show: options.header?.closeButton?.show ?? false,
          onClick: options.header?.closeButton?.onClick ?? null,
        },
      },

      backdrop: {
        show: options.backdrop?.show ?? true,
        onClick: options.backdrop?.onClick ?? null,
      },
    }
  }

  get maxWidth () {
    const { size } = this.finalOptions

    if (typeof size === 'number') return size

    switch (size) {
      case 'small': return 280
      case 'medium': return 560
      case 'auto': return 'revert'
      default: return 280
    }
  }
}
