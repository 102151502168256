
import { Vue, Component } from 'vue-property-decorator'
import windowSize from '@/window-size'
import Icon from 'vue-lasso-core/components/Icon.vue'

@Component({
  components: {
    Icon,
  },
})

export default class BrandingSurveyOverlay extends Vue {
  get layout () {
    if (windowSize.width > 768) return 'desktop'
    return 'mobile'
  }
}
