








import { Vue, Component, Prop } from 'vue-property-decorator'
import Icon from 'vue-lasso-core/components/Icon.vue'

@Component({
  components: {
    Icon,
  },
})

export default class Error extends Vue {
  @Prop({ type: String, required: true })
  readonly error!: string
}
