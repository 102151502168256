<script>
export default {
  props: {
    primary: Boolean,
    rounded: Boolean,
    small: Boolean,
    large: Boolean,
    disabled: Boolean,
  },
}
</script>

<style lang="scss" scoped>
@import "@shared/style/global.scss";
@import '@shared/style/_button.scss';

.button {
  @include buttonBase;
  @include buttonThemeDefault;
  padding: 6px 16px;
  -webkit-tap-highlight-color: transparent;

  &.is-small {
    padding: 4px 12px;
    @include typography('button-small');
  }

  &.is-large {
    padding: 8px 20px;
    @include typography('button-large');
  }

  &.is-primary {
    @include buttonThemePrimary;
  }

  &.is-rounded {
    border-radius: 16px;

    &.is-small {
      border-radius: 12px;
    }

    &.is-large {
      border-radius: 20px;
    }
  }
}
</style>

<template lang="pug">
  button(
    :class="{ 'button': true, 'is-primary': primary, 'is-rounded': rounded, 'is-small': small, 'is-large': large }"
    :disabled="disabled"
    @click="($event) => !disabled && $emit('click', $event)"
  )
    slot
</template>
