














































































import { Vue, Component, Prop, Model, Ref } from 'vue-property-decorator'
import Icon from 'vue-lasso-core/components/Icon.vue'
import IconButton from 'vue-lasso-core/components/IconButton.vue'
import InputBase from 'vue-lasso-core/components/InputBase.vue'

@Component({
  components: {
    Icon,
    IconButton,
    InputBase,
  },
})

export default class TextInput extends Vue {
  @Prop(Boolean)
  readonly autofocus!: boolean

  @Prop(String)
  readonly label?: string

  @Prop(Boolean)
  readonly required!: boolean

  @Prop(String)
  readonly name?: string

  @Prop({ type: String, default: 'text' })
  readonly type!: string

  @Prop(String)
  readonly autocomplete?: string

  @Model('input', { type: String, default: '' })
  readonly value!: string

  @Prop(String)
  readonly leadingIcon?: string

  @Prop(String)
  readonly trailingIcon?: string

  @Prop(Function)
  readonly onClickTrailingIcon?: VoidFunction

  @Prop(String)
  readonly helperText?: string

  @Prop(String)
  readonly errorText?: string

  @Ref()
  readonly inputEl!: HTMLInputElement

  focused = false

  mounted () {
    if (this.autofocus) this.inputEl.focus()
  }
}
