





























import { Vue, Component, Prop, Ref } from 'vue-property-decorator'

@Component({})

export default class LoadingSpinner extends Vue {
  @Ref()
  readonly path?: SVGPathElement

  @Prop({
    type: String,
    default: 'normal',
  })
  readonly preset!: string

  @Prop({
    type: Number,
    required: false,
  })
  readonly size!: number | null

  @Prop({
    type: Number,
    required: false,
  })
  readonly thickness!: number | null

  presets: any = {
    'normal': {
      size: 48,
      thickness: 4,
    },

    'medium': {
      size: 40,
      thickness: 4,
    },

    'small': {
      size: 24,
      thickness: 2,
    },

    'extra-small': {
      size: 18,
      thickness: 2,
    },
  }

  get finalPreset () {
    if (this.preset in this.presets) return this.presets[this.preset]
    return this.presets.normal
  }

  get finalSize () { return this.size ?? this.finalPreset.size }

  get finalThickness () { return this.thickness ?? this.finalPreset.thickness }

  get radius () { return (this.finalSize / 2) - this.finalThickness }

  pathLength = 0

  updatePathLength () { this.pathLength = this.path?.getTotalLength() || 0 }

  get progressPathLength () { return this.pathLength * 0.75 + this.finalThickness }

  rotation = 0
  dashOffset = 0

  animationStartTime = -1

  stopAnimation () { this.animationStartTime = -1 }

  startAnimation () {
    this.stopAnimation()

    const startTime = performance.now()
    this.animationStartTime = startTime

    const ease = (t: number) => t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2

    const onFrame = (time: number) => {
      if (this.animationStartTime !== startTime) return

      const timePassed = time - startTime

      const factor = timePassed / 750
      const easingFactor = ease(factor % 1)
      const grow = factor % 2 <= 1
      const shrink = !grow

      const maxDashOffset = this.progressPathLength - this.finalThickness
      this.dashOffset = maxDashOffset * (grow ? 1 - easingFactor : easingFactor)

      const rotation = (
        (360 * ((timePassed / 1500) % 1)) // Linear rotation

        + (270 * Math.floor(factor / 2)) // Previous shrink offset

        + (shrink ? 270 * easingFactor : 0) // Shrink offset
      ) % 360

      this.rotation = rotation

      requestAnimationFrame(onFrame)
    }

    onFrame(startTime)
  }

  mounted () {
    this.updatePathLength()
    this.startAnimation()
  }

  beforeDestroy () {
    this.stopAnimation()
  }
}
