var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'input-base': true,

    'has-leading-icon': _vm.leadingIcon,
    'has-trailing-icon': _vm.trailingIcon || _vm.errorText,
    'has-trailing-icon-on-click': _vm.trailingIconOnClick,

    'is-focused': _vm.isFocused,
    'has-value': _vm.hasValue,
    'has-error': _vm.errorText,
  }},[(!_vm.floatingLabel)?_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{staticClass:"input-box"},[(_vm.leadingIcon)?_c('div',{staticClass:"leading-icon"},[_c('Icon',{attrs:{"icon":_vm.leadingIcon}})],1):_vm._e(),(_vm.floatingLabel)?_c('label',{staticClass:"floating-label"},[_c('div',{staticClass:"label-text"},[_vm._v(_vm._s(_vm.label))])]):_vm._e(),_vm._t("default"),(_vm.trailingIcon)?_c(_vm.trailingIconOnClick ? 'button' : 'div',{tag:"div",staticClass:"trailing-icon",on:{"click":function($event){$event.preventDefault();return (function () { _vm.trailingIconOnClick && _vm.trailingIconOnClick() }).apply(null, arguments)}}},[_c('Icon',{attrs:{"icon":_vm.trailingIcon}})],1):(_vm.errorText)?_c('div',{staticClass:"trailing-icon"},[_c('Icon',{attrs:{"icon":"error"}})],1):_vm._e()],2),(_vm.helperText && !_vm.errorText)?_c('div',{staticClass:"helper-text"},[_vm._v(_vm._s(_vm.helperText))]):(_vm.errorText)?_c('div',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.errorText))]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }