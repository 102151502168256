<template>
  <div id="searchBar" ref="searchBar" :class="{narrow: isNarrow}">
    <!-- Search Input Field + Filter -->
    <search-input 
      :EventBus="EventBus"
      :query="query"
      :status-filters="statusFilters"
      :status-filter="statusFilter"
      :focus-on-search-bar="focusOnSearchBar"
      :is-narrow="isNarrow"
      :entity-types="entityTypes"
      @status-changed="status => {$emit('status-changed', status);resetSelectedIndex()}" 
      @query-changed="query => $emit('query-changed', query)" 
      @search-bar-focused="focus => $emit('search-bar-focused', focus)"
      @keyboard-input="EmittedKeyboardInput"
    />
    <!-- Results Dropdown -->
    <results-dropdown 
      :query="query"
      :results="results"
      :no-more-results="noMoreResults"
      :entity-types="entityTypes"
      :entity-type="entityType" 
      :loading="loading"
      :loading-next-page="loadingNextPage"
      :open="isOpen"
      :is-narrow="isNarrow"
      :selected-index="selectedIndex"
      @entity-type-changed="entityType => {$emit('entity-type-changed', entityType);resetSelectedIndex()}" 
      @entity-selected="entity => $emit('entity-selected', entity)"
      @scrolled-to-bottom="atBottom => $emit('scrolled-to-bottom', atBottom)"
    />  
  </div>
</template>

<script>
import SearchInput from './components/SearchInput'
import ResultsDropdown from './components/ResultsDropdown'

export default {
  name: 'SearchBar',
  props: {
    EventBus: {
      type: Object,
      required: true
    },
    query: {
      type: String,
      required: false
    },
    results: {
      type: Array,
      required: true
    },
    noMoreResults: {
      type: Boolean
    },
    entityTypes: {
      type: Array,
      required: true
    },
    entityType: {
      type: String,
      required: true
    },
    statusFilters: {
      type: Array,
      required: true
    },
    statusFilter: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean
    },
    loadingNextPage: {
      type: Boolean
    },
    focusOnSearchBar: {
      type: Boolean
    },
    isNarrow: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      open: false,
      selectedIndex: -1
    }
  },
  computed: {
    isOpen: {
      get () {
        return this.open || (this.query.length > 0 && this.focusOnSearchBar)
      },
      set (val) {
        this.open = val
      }
    }
  },
  methods: {
    onOutsideClick (event) {
      if (this.$refs.searchBar && (event.type === 'blur' || !this.$refs.searchBar.contains(event.target))) {
        this.$emit('search-bar-focused', false)
        this.isOpen = false
      }
    },
    EmittedKeyboardInput (event) {
      switch (event.key) {
        case 'ArrowUp':
          if (this.selectedIndex === 0) this.selectedIndex = this.results.length-1
          else this.selectedIndex--
          break;
        case 'ArrowDown':
          if (this.selectedIndex === this.results.length-1) this.selectedIndex = 0
          else this.selectedIndex++  
          break;
        case 'Enter':
          if (this.selectedIndex > -1) {
            this.$emit('entity-selected', this.results[this.selectedIndex])
            event.target.blur()
          }
          break;
      }
    },
    resetSelectedIndex () {
      this.selectedIndex = -1
    }
  },
  watch: {
    results: function () {
      if (this.results.length === 1) this.selectedIndex = 0
    },
    query: function () {
      this.resetSelectedIndex()
    }
  },  
  mounted () {
    this.EventBus.$on('app-click', this.onOutsideClick)
  },
  beforeDestroy () {
    this.EventBus.$off('app-click', this.onOutsideClick)
  },
  components: {
		SearchInput,
    ResultsDropdown
  }
}
</script>

<style lang="less">
  #searchBar {
    width: 100%;
    position: relative;
    &.narrow {
      position: inherit;
    }
    .vb {
        &.dragger-wide {
          >.vb-dragger {
            width: 15px;
          }
        }

        .vb-content {
          -webkit-overflow-scrolling: touch;
        }

        &.dark {
          &:hover {
            .vb-dragger-styler {
              background-color: #dfdfdf;
            }
          }

          &.vb-dragging-phantom { 
            >.vb-dragger {
              >.vb-dragger-styler {
                background-color: #dfdfdf;
              }
            }
          }
        }

        &:hover {
          >.vb-dragger-styler {
            margin: 0px;
            height: 100%;
          }
        }

        >.vb-dragger {
          z-index: 5;
          width: 6px;
          right: 0;

          >.vb-dragger-styler {
            height: 100%;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            -webkit-transform: rotate3d(0, 0, 0, 0);
            transform: rotate3d(0, 0, 0, 0);
            -webkit-transition: background-color 100ms ease-out,
            margin 100ms ease-out,
            height 100ms ease-out;
            transition: background-color 100ms ease-out,
            margin 100ms ease-out,
            height 100ms ease-out;
            background-color: rgba(255, 255, 255, 0);
          }
        }
      }
  }
</style>