


















import { Vue, Component, Model } from 'vue-property-decorator'
import CustomRadioButton from './CustomRadioButton.vue'
import Icon from './Icon.vue'
import RadioButton from './RadioButton.vue'

const generateName = (() => {
  let i = 0
  return () => `smiley-field-${i++}`
})()

@Component({
  components: {
    CustomRadioButton,
    Icon,
    RadioButton,
  },
})

export default class SmileyField extends Vue {
  @Model('change', { type: Number, default: null })
  readonly value!: number | null

  readonly name = generateName()

  readonly options = [
    {
      icon: 'sentiment_very_dissatisfied',
      value: 1,
      text: 'Meget uenig',
    },
    {
      icon: 'sentiment_dissatisfied',
      value: 2,
      text: 'Uenig',
    },
    {
      icon: 'sentiment_neutral',
      value: 3,
      text: 'Neutral',
    },
    {
      icon: 'sentiment_satisfied',
      value: 4,
      text: 'Enig',
    },
    {
      icon: 'sentiment_very_satisfied',
      value: 5,
      text: 'Meget enig',
    },
  ]
}
