





























































































import { Vue, Component, Prop } from 'vue-property-decorator'
import LoadingSpinner from 'vue-lasso-core/components/LoadingSpinner.vue'
import Header from '../../parts/Header.vue'
import Error from '../../parts/Error.vue'
import TextField from '@/components/new-fields/TextField.vue'
import PasswordField from '@/components/new-fields/PasswordField.vue'
import Button from '@/components/Button.vue'
import userApi from '@/modules/users/api'
import userStore from '@/modules/users/store'
import forgotPasswordDialogStore from '@/components/ForgotPasswordDialog/store'
import router from '../router'
import externalLoginProviders, { Provider } from '../../modules/external-login/providers'
import externalLoginPopup, { ExternalLoginCallbackResult } from '../../modules/external-login/popup'
import LoginDialogTemplateOptions from '../../options'
import externalLoginWelcomeDialogStore from '@/components/ExternalLoginWelcomeDialog/store'
import { trackGoal } from '@/utils/plausible'
import { lassoxAppsUrl } from '@/lasso-urls'
import { validateEmail, validatePassword } from '../../modules/validation'
import confirmEmailSentDialogStore from '@/components/ConfirmEmailSentDialog/store'

@Component({
  components: {
    LoadingSpinner,
    Header,
    Error,
    Button,
    TextField,
    PasswordField,
  },
})

export default class Home extends Vue {
  @Prop({ type: Object, required: true })
  options!: LoginDialogTemplateOptions

  loading = false

  userStore = userStore
  forgotPasswordDialogStore = forgotPasswordDialogStore
  router = router
  externalLoginProviders = externalLoginProviders

  error: string | null = null

  emailLoginModel = {
    email: {
      value: '',
      error: '',
    },
    password: {
      value: '',
      error: '',
    },
  }

  get emailLoginHasErrors () {
    for (const field of Object.values(this.emailLoginModel)) {
      if (!!field.error) return true
    }

    return false
  }

  validateEmail () {
    this.emailLoginModel.email.error = validateEmail(this.emailLoginModel.email.value).error ?? ''
  }

  validatePassword () {
    this.emailLoginModel.password.error = validatePassword(this.emailLoginModel.password.value).error ?? ''
  }

  submitEmailLogin () {
    if (this.loading) return

    this.loading = true
    this.error = null

    userApi.login({
      email: this.emailLoginModel.email.value,
      password: this.emailLoginModel.password.value,
    }).then(result => {
      if (!result.ok) {
        switch (result.data.errorCode) {
          case 10: {
            // that.loginError = 'Forkert brugernavn eller password'
            this.error = 'Forkert e-mail eller adgangskode.'
            break
          }

          case 14: {
            // let time = ('0' + new Date(error.body.errorMessage).getHours()).slice(-2) + ':' + ('0' + new Date(error.body.errorMessage).getMinutes()).slice(-2)
            // that.loginError = 'For mange loginforsøg. Prøv igen efter kl. ' + time + '.'
            const time = ('0' + new Date(result.data.errorMessage).getHours()).slice(-2) + ':' + ('0' + new Date(result.data.errorMessage).getMinutes()).slice(-2)
            this.error = 'For mange loginforsøg. Prøv igen efter kl. ' + time + '.'
            break
          }

          case 19: {
            // that.mustConfirmEmail = true
            // that.$store.state.loggingIn = true
            // setTimeout(() => {
            //   that.$router.push({ name: 'emailconfirmation' })
            // }, 300)
            userStore.hideLoginDialog()
            confirmEmailSentDialogStore.show(this.emailLoginModel.email.value)
            break
          }

          default: {
            this.error = 'Der skete en fejl. Prøv igen.'
            console.error('Email login failed. Data:', result.data)
            break
          }
        }

        this.loading = false
        return
      }

      const redirectUrl = new URL(`${lassoxAppsUrl}/login`)
      redirectUrl.searchParams.set('token', result.data)
      if (this.options.redirectUrl) redirectUrl.searchParams.set('returnUrl', this.options.redirectUrl)
      window.location.href = redirectUrl.toString()
    })
  }

  openExternalLoginPopup (provider: Provider) {
    this.error = null

    externalLoginPopup.open(provider).then(result => {

      if (result.type === 'NewUser') {
        trackGoal('Signup', {
          callback: () => this.redirectAfterExternalLogin(result, this.options.redirectUrl),
          props: {
            provider: provider,
            trigger: this.options.trackingEventName.replaceAll('+', ' ')
          },
        })
      } else {
        trackGoal('Login', {
          callback: () => this.redirectAfterExternalLogin(result, this.options.redirectUrl),
          props: {
            provider: provider,
            trigger: this.options.trackingEventName.replaceAll('+', ' ')
          }
        })
      }
    })
    .catch(error => {
      if (error.errorCode === 110) this.error = `Du har allerede en bruger, som logges ind via ${error.errorMessage}`
      else if (error.errorCode === 16) this.error = 'Du har allerede en bruger, som logges ind vha. e-mail.'
      else if (error.errorCode === 58) this.error = 'E-mails fra det valgte domæne er ikke tilladt.'
      else this.error = 'Noget gik galt'
      console.error('External login failed. Error:', error)
    })
  }

  redirectAfterExternalLogin = (result: ExternalLoginCallbackResult, returnUrl?: string) => {
    const redirectUrl = new URL(`${lassoxAppsUrl}/login`)
    redirectUrl.searchParams.set('token', result.signInToken)
    if (returnUrl) redirectUrl.searchParams.set('returnUrl', returnUrl)
    window.location.href = redirectUrl.toString()
  }
}
