import Vue from 'vue'
import { Provider } from './providers'
import { lassoxAppsUrl } from '@/lasso-urls'

const popupId = 'lasso.dk-external-login-popup'

const popup = new Vue({
  data: {
    _resolve: <((result: ExternalLoginCallbackResult) => void) | null> null,
    _reject: <((error?: unknown) => void) | null> null,
    _window: <Window | null> null,
    _interval: <number | null> null,
  },

  methods: {
    open (provider: Provider) {
      return new Promise<ExternalLoginCallbackResult>((resolve, reject) => {
        this.close()

        this._resolve = result => {
          this.close()
          resolve(result)
        }

        this._reject = error => {
          this.close()
          reject(error)
        }

        this._window = window.open(`${lassoxAppsUrl}/login/external/${provider}?source=lasso.dk`, popupId, `
          scrollbars=yes,
          resizable=yes,
          width=500,
          height=500,
        `)

        if (!this._window) throw 'Could not open external login popup'

        this._window.focus()

        window.addEventListener('message', this.handleMessage)

        this._interval = window.setInterval(() => {
          if (this._window?.closed) popup.close()
        }, 500)
      })
    },

    close () {
      window.removeEventListener('message', this.handleMessage)

      if (this._window) this._window.close()
      if (this._interval) clearInterval(this._interval)

      this._window = null
      this._interval = null
      this._resolve = null
      this._reject = null
    },

    handleMessage (msg: MessageEvent) {
      if (msg.origin !== lassoxAppsUrl) return

      let data: any

      try {
        data = JSON.parse(msg.data)
      } catch {
        return
      }

      if (data.name != 'ExternalLoginCallback') return

      const {
        _resolve: resolve,
        _reject: reject,
      } = this

      if (!resolve || !reject) return

      if (typeof data.ok != 'boolean') {
        console.error('Expected data.ok to be a boolean')
        return reject()
      }

      if (data.result != null) {
        if (typeof data.result != 'object') {
          console.error('Expected data.result to be an object or null')
          return reject()
        }

        const result: Record<string, unknown> = data.result

        switch (result.type) {
          case 'ExistingUser':
          case 'NewUser':
          case 'MultipleUsers': {
            // case 'Failed': {
            break
          }

          default: {
            console.error('Expected data.result.type to be one of these values:', [
              'ExistingUser',
              'NewUser',
              'MultipleUsers',
              // 'Failed',
            ])
            return reject()
          }
        }

        if (typeof result.signInToken !== 'string') {
          console.error('Expected data.result.signInToken to be a string')
          return reject()
        }
      }

      if (data.ok != true) {
        if ('error' in data) return reject(data.error)
        return reject()
      }

      resolve(data.result)
    },
  },

  beforeDestroy () {
    this.close()
  },
})

export default popup

export interface ExternalLoginCallbackResult {
  type: (
    | 'ExistingUser'
    | 'NewUser'
    | 'MultipleUsers'
    // | 'Failed'
  )
  signInToken: string
}
