









































import { Vue, Component } from 'vue-property-decorator'
import Dialog from 'vue-lasso-core/components/Dialog.vue'
import store from './store'
import userApi from '@/modules/users/api'

@Component({
  components: {
    Dialog,
  },
})

export default class ConfirmEmailSentDialog extends Vue {
  store = store
  sentTime: Date | null = null

  async resendWelcomeEmail() {
    await userApi.resendWelcomeMail(this.store.state.email, this.store.state.lassoOrganizationId, this.store.state.emailRedirectUrl)
    this.sentTime = new Date()
  }
}
