<template>
  <span class="pep-wrapper" v-if="pep">
    <span class="pep" ref="pep" :class="{'no-hover': !withTooltip }">
      <div v-if="withTooltip" class="pep-tooltip" ref="pep-tooltip" :class="below ? 'bottom' : placement">
        <span class="tooltip-reason">{{pep.reason}}</span>
      </div>
      <span>PEP</span>
    </span>
  </span>
</template>

<script>
export default {
  name: 'result-list-row',
  props: {
    below: {
      type: Boolean,
      required: false,
      default: false
    },
    placement: {
      type: String,
      default: 'top'
    },
    id: {
      type: [String, Number],
      required: true
    },
    pepList: {
      type: Object
    },
    pepData: {
      type: Object
    },
    withTooltip: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      aligned: false
    }
  },
  created () {},
  mounted () {},
  computed: {
    pep () {
      return this.pepData || (this.pepList ? this.pepList[this.id.toString().replace('CVR-3-', '')] : null)
    }
  },
  methods: {}
}
</script>

<style lang="less">
  .pep-wrapper {
    .pep {
      position: relative;
      cursor: pointer;
      border-radius: 2px;
      font-weight: 500;
      color: #fff;
      background-color: #222;
      padding: 0.2em 0.4em;
      
      .pep-tooltip {
        position: absolute;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #fff;
        z-index: 2;
        width: 250px; 
        text-align: center;
        left: 50%;
        padding: 5px 0;
        display: none;
        &.top {
          bottom: 100%;
          transform: translate(-50%, 0);
        }
        &.bottom {
          top: 100%;
          transform: translate(-50%, 0);
        }
        &.right {
          left: 100%;
          top: 50%;
          transform: translate(0, -50%);
          padding: 0 5px;
        }
        .tooltip-reason {
          background:#222;
          display: inline-block;
          padding: 10px;
          border-radius: 2px;
        }
      }
      &.no-hover {
        cursor: default;
      }
      
      &:hover {
        .pep-tooltip {
          display: inline-block;
        }
      }
    }
  }
</style>