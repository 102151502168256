
import { Vue, Component, Prop } from 'vue-property-decorator'
import Icon from './Icon.vue'

@Component({
  components: {
    Icon,
  },
})
export default class InputBase extends Vue {
  @Prop({ type: String, required: false }) readonly label: string | undefined
  @Prop({ type: Boolean, required: false }) readonly floatingLabel: boolean | undefined

  @Prop({ type: String, required: false }) readonly leadingIcon: string | undefined
  @Prop({ type: String, required: false }) readonly trailingIcon: string | undefined
  @Prop({ type: Function, required: false }) readonly trailingIconOnClick: Function | undefined

  @Prop({ type: String, required: false }) readonly helperText: string | undefined
  @Prop({ type: String, required: false }) readonly errorText: string | undefined

  @Prop({ type: Boolean, required: false }) readonly isFocused: boolean | undefined
  @Prop({ type: Boolean, required: false }) readonly hasValue: boolean | undefined
}
