var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
      'xxs': _vm.windowSize.size === 'xxs',
      'xs min-xxs min-xs': _vm.windowSize.size === 'xs',
      'sm min-xxs min-xs min-sm': _vm.windowSize.size === 'sm',
      'md min-xxs min-xs min-sm min-md': _vm.windowSize.size === 'md',
      'lg min-xxs min-xs min-sm min-md': _vm.windowSize.size === 'lg',
      'xl min-xxs min-xs min-sm min-md min-lg': _vm.windowSize.size === 'xl',
    },staticStyle:{"height":"100%"},attrs:{"id":"app"},on:{"click":_vm.onClick}},[(!_vm.isEmbedded)?_c('app-header'):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.initializing),expression:"!initializing"}],staticStyle:{"position":"relative"}},[_c('div',{style:([
          'position: relative; z-index: 0;',
          _vm.overlayStore.overlays.length && 'height: 0; overflow: hidden;' ].join(' '))},[_c('TopBanners'),_c('SideBanners',[_c('router-view',{staticStyle:{"max-width":"1140px","flex-grow":"1","width":"100%"}})],1),(!_vm.isEmbedded)?_c('app-footer'):_vm._e()],1),(!_vm.isEmbedded)?_vm._l((_vm.overlayStore.overlays),function(overlay,i){return _c(overlay.component,{key:overlay.key,tag:"component",style:([
            'z-index: 0; grid-row-start: 1; grid-column-start: 1;',
            i !== _vm.overlayStore.overlays.length - 1 && 'height: 0; overflow: hidden;'
          ].join(' ')),on:{"close":function () { return _vm.overlayStore.hideOverlay(overlay.key); }}})}):_vm._e()],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading || _vm.initializing),expression:"loading || initializing"}],staticClass:"loader-wrapper"},[(_vm.initializing)?_c('loader'):_vm._e()],1),(_vm.showOptIn)?_c('opt-in-modal',{attrs:{"config":_vm.optInConfig},on:{"close":function($event){_vm.showOptIn = false}}}):_vm._e(),(_vm.showConfirmNewsletter)?_c('confirm-newsletter-modal',{on:{"close":function($event){_vm.showConfirmNewsletter = false}}}):_vm._e(),_c('alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAlert),expression:"showAlert"}],attrs:{"placement":_vm.windowSize.width < _vm.windowSize.sizes.xs ? 'top' : 'top-right',"duration":_vm.alertConfig.duration || 2000,"type":_vm.alertConfig.type || 'success',"dismissable":_vm.alertConfig.dismissable || false},model:{value:(_vm.showAlert),callback:function ($$v) {_vm.showAlert=$$v},expression:"showAlert"}},[_c('p',{staticClass:"margin-0",domProps:{"innerHTML":_vm._s(_vm.alertConfig.content ? _vm.alertConfig.content : '&nbsp')}},[_vm._v(_vm._s(_vm.alertConfig.content))])]),_c('LoginDialog'),_c('LoginToFollowDialog'),_c('LoginToShowCompanyDialog'),_c('LoginToShowKeyfiguresDialog'),_c('ConfirmEmailSentDialog'),_c('ExternalLoginWelcomeDialog'),_c('ForgotPasswordDialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }