



































import { Vue, Component, Prop, Model } from 'vue-property-decorator'

@Component({})

export default class TextField extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly dense!: boolean

  @Prop({ type: String })
  readonly label?: string

  @Prop({ type: String, default: 'text' })
  readonly type!: string

  @Prop({ type: String })
  readonly autocomplete?: string

  @Prop({ type: Number })
  readonly rows?: number

  get finalRows () { return Math.max(this.rows ?? 0, 1) }

  @Model('input', { type: String, default: '' })
  readonly value!: string

  @Prop({ type: String })
  readonly helper?: string

  @Prop({ type: String })
  readonly error?: string
}
